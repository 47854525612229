<script lang="ts">
  import * as drawer from '@velope/ott-o-rama-core/drawer'
  import { FOCUS_MAP, FALLBACK_FOCUS_MAP } from '@velope/ott-o-rama-core/navigation'
  import { title } from '@velope/ott-o-rama-core/browser'
  import { focus, splash } from './stores'
  import Player from './components/Player.svelte'
  import Detail from './components/Detail.svelte'
  import SideDrawer from './components/SideDrawer.svelte'
  import Browser from './components/Browser.svelte'
  
  import '@velope/ott-o-rama-core/css'

  let focusedCarousel = ''
  $: if ($focus.focused.startsWith('carousel:')) focusedCarousel = $focus.focused

  $: showBrowser = $focus.focused === 'side_drawer' || $focus.focused.startsWith('carousel:')

  $: if (splash.isLoaded() && $splash.initialized && $splash.loading) splash.removeSplash()

  const focusOnDetails = () => {
    focus.focusOn('detail')
  }

  const focusOnCarousel = () => {
    focus.focusOn(focusedCarousel)
  }

  const focusOnSideDrawer = () => {
    focus.focusOn('side_drawer')
  }

  const onKeyDown = (e: KeyboardEvent) => {
    const focusFn = FOCUS_MAP[e.code] ? FOCUS_MAP[e.code] : FALLBACK_FOCUS_MAP[e.keyCode]
    if (focusFn === '') return

    const currentFocus = $focus.map[$focus.focused]
    const focusTarget = currentFocus[focusFn]

    if ((focusTarget ?? '') !== '') {
      if (typeof focusTarget === 'string') {
        focus.focusOn(focusTarget)
        if (typeof currentFocus?.onUnfocus === 'function') currentFocus.onUnfocus()
      } else if (typeof focusTarget === 'function') {
        focusTarget()
      }
    }
  }
</script>

<svelte:head>
  <title>{title('Svelte')}</title>
</svelte:head>

<svelte:body on:keydown={onKeyDown} />

<div class="app">
  {#if $splash.loading}
    <div class="splash">
      <div class="loader" />
      <div class="splash-title">
          { title('Svelte') }
      </div>
    </div>
  {/if}
  <div class="overlay" />
  <div class="player" class:hidden={$focus.focused !== 'player'}>
    <Player id="player" on:back={focusOnDetails} />
  </div>
  {#if $focus.focused === 'detail'}
    <div class="detail">
      <Detail id="detail" on:back={focusOnCarousel} />
    </div>
  {/if}
  <div class="main">
    <div
      class="drawer"
      aria-current={$focus.focused === 'side_drawer' ? true : undefined}
      hidden={!showBrowser}
    >
      <SideDrawer
        id="side_drawer"
        title={drawer.TITLE}
        items={drawer.ITEMS}
        onRight={focusedCarousel}
      />
    </div>
    <div
      class="browser"
      aria-current={$focus.focused !== 'side_drawer' ? true : undefined}
      hidden={!showBrowser}
    >
      <Browser on:back={focusOnSideDrawer} />
    </div>
  </div>
</div>
