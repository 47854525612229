<script lang="ts">
  import type { Detail } from '@velope/ott-o-rama-core/media'
  import {
    cloudinaryUrl,
    imageAlt,
    name as shortName,
    airDate,
    ITEM_WIDTH,
    preload,
  } from '@velope/ott-o-rama-core/item'
  import { getPosterUrl } from '@velope/ott-o-rama-core/media'
  import { isEnabled } from '@velope/ott-o-rama-core/cloudinary'
  import { useWebWorker } from '@velope/ott-o-rama-core/webworker'
  import { screen, splash, webWorker } from '../stores'
  import Lazy from './Lazy.svelte'

  export let item: Detail
  export let focused: boolean

  const useCloudinary = isEnabled()
  const _useWebWorker = useWebWorker()
  const _preload = preload()
  const onImgLoad = () => {
    if ($splash.loading) {
      splash.addToProgress()
      if (useCloudinary) splash.addToProgress() // Add 2 for normal and focused posters
    }
  }
  $: width = ITEM_WIDTH * $screen.scale * window.devicePixelRatio
  $: src = _useWebWorker && useCloudinary
            ? $webWorker.images[`${focused}-cloudinary${item.poster}`]
            : _useWebWorker
            ? $webWorker.images[item.poster]
            : useCloudinary
            ? cloudinaryUrl(item, focused, width)
            : getPosterUrl(item.poster, width)

  $: alt = imageAlt(item.name)

  $: name = shortName(item.name)

  $: if (src === null) onImgLoad()
</script>

<svelte:head>
  {#if useCloudinary && _preload}
    <link rel="preload" as="image" href={cloudinaryUrl(item, false, width)} />
    <link rel="preload" as="image" href={cloudinaryUrl(item, true, width)} />
  {/if}
</svelte:head>

<div class="item" aria-current={focused ? 'true' : undefined}>
  {#if useCloudinary}
    <Lazy>
      <img {src} {alt} on:load={onImgLoad} on:error={onImgLoad} />
    </Lazy>
  {:else}
    <div class="item-poster">
      <Lazy>
        <img {src} {alt} on:load={onImgLoad} on:error={onImgLoad} />
      </Lazy>
      <span class="item-runtime" aria-selected={focused ? 'true' : undefined}>1 min</span>
    </div>
    <div class="item-description">
      <div class="item-air_date">{airDate(item.airDate)}</div>
      <div class="item-name">{name}</div>
      <div class="item-rating">{item.voteAverage}</div>
    </div>
  {/if}
</div>
