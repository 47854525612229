<script context="module" lang="ts">
  import { init, getAnimationLibrary, getDuration } from '@velope/ott-o-rama-core/motion'
  import { getMode, shouldSetMode, setValue, shouldSetValue } from '@velope/ott-o-rama-core/scroll'
  import { cubicOut } from 'svelte/easing'

  const mode = getMode()
  const duration = getDuration()
  const easing = cubicOut
  const lib = getAnimationLibrary()
  // cache the result for performance
  const _shouldSetValue = shouldSetValue(lib)

  init(lib)
</script>

<script lang="ts">
  import type { ScrollProperty } from '@velope/ott-o-rama-core/scroll'
  import { writable } from 'svelte/store'
  import { tweened } from 'svelte/motion'

  export let property: ScrollProperty = 'scrollLeft'
  export let value: number

  let div: HTMLDivElement
  $: element = div?.firstElementChild as HTMLElement

  $: if (shouldSetMode(element, mode)) element.style.scrollBehavior = mode

  const scrollValue =
    mode === 'tween' && lib === 'native' ? tweened(0, { duration, easing }) : writable(0)
  // `scroll=auto` fails to update without this
  $: if (mode !== 'tween') scrollValue.set(value)
  // cache the value (for tweening)
  let _value = value
  const callback = (v: number) => scrollValue.set(v)
  $: {
    // detect scrolling state and skip
    // we want to ignore reactivity triggered by using `element` (-> `div`)
    if (_value !== value) {
      setValue(element, property, value, mode, duration, lib, callback)
      _value = value
    }
  }

  $: if (element !== undefined && _shouldSetValue) element[property] = $scrollValue
</script>

<div bind:this={div}>
  <slot />
</div>
