<script context="module" lang="ts">
  import { init, getMode, getDuration, doTransform, applyTransform } from '@velope/ott-o-rama-core/motion'

  const duration = getDuration()
  const _doTransform = doTransform()
  const mode = getMode()
  init(mode)
</script>

<script lang="ts">
  export let x: number
  export let y: number
  export let scale: number = 1
  // export let easing = 'ease-out'

  let div: HTMLDivElement
  let cx: number = x
  let cy: number = y

  $: {
    if (_doTransform) {
      applyTransform(div, mode, { x, y, cx, cy, scale, ms: duration, accel: false })
      cx = x
      cy = y
    }
  }
</script>

<div bind:this={div} class:transform={true}>
  <slot />
</div>
