<script context="module" lang="ts">
  import { doScroll, doTransform } from '@velope/ott-o-rama-core/motion'
  import { isEnabled } from '@velope/ott-o-rama-core/cloudinary'
  import { useWebWorker } from '@velope/ott-o-rama-core/webworker'
  import { renderRange } from '@velope/ott-o-rama-core/browser'

  const _doScroll = doScroll()
  const _doTransform = doTransform()
  const useCloudinary = isEnabled()
  const _useWebWorker = useWebWorker()
  const _renderRange = renderRange()
</script>

<script lang="ts">
  import type { Detail } from '@velope/ott-o-rama-core/media'
  import {
    getIndex,
    shouldAddList,
    getPage,
    title,
    subtitle,
    isCarousel,
    getY,
    adjustTop,
    startingCarousels,
    carouselsToLoad,
  } from '@velope/ott-o-rama-core/browser'
  import { focus, screen, splash, webWorker } from '../stores'
  import Carousel from './Carousel.svelte'
  import Scroll from './Scroll.svelte'
  import Transform from './Transform.svelte'

  let page = 0
  let loading = true
  let errorMessage = ''
  let lists: Detail[][] = []
  let loadingLists = startingCarousels()

  const ON_SCREEN_ITEMS = _renderRange * 2
  const OFF_SCREEN_ITEMS = _renderRange

  const initList = async (index: number) => {
    lists[index] = await getPage(++page)
    lists = lists
    loadingLists--
  }

  const onPage = async (index: number) => {
    const _page = await getPage(++page)
    lists[index] = lists[index].concat(..._page)
    lists = lists
  }

  const listsPromises = Array.from(new Array(startingCarousels())).map(async (_, i) => {
    await initList(i)
  })

  Promise.all(listsPromises)
    .then(() => {
      lists.map((items) => {
        splash.setProgress(useCloudinary ? items.length * 2 : items.length)
      })
      loading = false
    })
    .catch((data) => {
      console.log(data)
      errorMessage = data.message
      loading = false
      splash.removeSplash()
    })

  if (_useWebWorker) {
    webWorker.init()
    $webWorker.worker.addEventListener('message', (workerData: MessageEvent) => {
      webWorker.store(workerData.data)
    })
  }

  $: _subtitle = subtitle($screen.viewport)

  let focusedCarousel = 'carousel:1'
  focus.focusOn(focusedCarousel)

  $: if (isCarousel($focus.focused)) focusedCarousel = $focus.focused
  $: focusedCarouselIndex = getIndex(focusedCarousel)

  // incase the screen scale doesn't round well, hide the sometimes stray last pixel row of previous carousel (+ 1)
  $: value = getY(focusedCarouselIndex) * $screen.scale + 1
  $: scrollValue = _doScroll ? value : 0
  $: transformValue = _doTransform ? -value : 0

  $: if (shouldAddList(lists.length, focusedCarouselIndex) && loadingLists === 0) {
    loadingLists = carouselsToLoad()
    for (let i = 0; i < carouselsToLoad(); i++)
      initList(lists.length + i)
  }
</script>

<div class="browser-header">
  {title('Svelte')} <span>{_subtitle}</span>
</div>

{#if loading}
  <p>...waiting</p>
{:else if errorMessage}
  <p class="browser-alert-error">{errorMessage}</p>
{:else }
  <Scroll property="scrollTop" value={scrollValue}>
    <div class="browser-carousels">
      <Transform x={0} y={transformValue}>
        {#each lists as items, i}
            {#if _renderRange === 0 || (i >= focusedCarouselIndex - OFF_SCREEN_ITEMS && i < focusedCarouselIndex + ON_SCREEN_ITEMS)}
              <div class="carousel-wrapper" style:top={adjustTop(i)}>
                <Carousel
                  id="carousel:{i + 1}"
                  items={items ?? []}
                  onLeft="side_drawer"
                  onUp="carousel:{Math.max(i, 1)}"
                  onDown="carousel:{Math.min(i + 2, lists.length)}"
                  on:page={() => onPage(i)}
                  on:back
                />
              </div>
            {/if}
        {/each}
      </Transform>
    </div>
  </Scroll>
{/if}
