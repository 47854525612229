<script context="module" lang="ts">
  import { disableLazy } from '@velope/ott-o-rama-core/lazy'

  const _disableLazy = disableLazy()
</script>

<script lang="ts">
  import { onMount } from 'svelte'
  import { hasIntersectionObserver } from '@velope/ott-o-rama-core/lazy'
  import { splash } from '../stores'

  let container: HTMLDivElement
  let observer : IntersectionObserver
  let intersecting = false

  onMount(() => {
    if (!hasIntersectionObserver()) {
      intersecting = true
      return
    }

    observer = new IntersectionObserver((entries) => {
      intersecting = entries
        .map((entry) => entry.isIntersecting)
        .reduce((acc, curr) => acc || curr, intersecting)

      if (intersecting) {
        observer.unobserve(container)
      }
    })

    return () => {
      if (container !== null) observer.disconnect()
    }
  })

  $: if (container) observer.observe(container)
</script>

{#if intersecting || _disableLazy || $splash.loading}
  <slot />
{:else}
  <div bind:this={container} class="lazy">
    <div name="placeholder" />
  </div>
{/if}
