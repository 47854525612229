<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { detail, playback, focus } from '../stores'
  import { getVideo, getYouTubeEmbedUrl } from '@velope/ott-o-rama-core/media'
  import { isYouTube, onKeyDown, play, shouldPause } from '@velope/ott-o-rama-core/player'

  export let id: string

  const dispatch = createEventDispatcher()

  const onBack = () => {
    dispatch('back')
  }

  let el: Element

  $: video = $detail?.id > 0 ? getVideo($detail.type, $detail.id) : undefined
  $: focus.register(id, { onBack })
  $: if ($focus.focused === 'player') play(true, el)
  $: if ($focus.focused !== 'player' && shouldPause()) play(false, el)
</script>

<svelte:body on:keydown={(e) => onKeyDown(e, el)} />

{#await video}
  <p>Loading...</p>
{:then _video}
  {#if isYouTube(_video)}
    <iframe
      bind:this={el}
      title={_video.name}
      src={getYouTubeEmbedUrl(_video.key)}
      frameborder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      data-playing="false"
    />
  {:else if $playback.url !== ''}
    <video bind:this={el} width="100%" height="100%">
      <track kind="captions" />
      <source src={$playback.url} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  {:else}
    <h1>Nothing playing...</h1>
  {/if}
{:catch err}
  <p>Error fetching video: {err}</p>
{/await}
