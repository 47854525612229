<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { detail, playback, focus, screen } from '../stores'
  import {
    getDetails,
    getRuntime,
    getRuntimeStr,
    getPosterUrl,
    getBackdropUrl,
  } from '@velope/ott-o-rama-core/media'

  export let id: string

  const dispatch = createEventDispatcher()

  const buttons = ['play', 'back']
  let btnIndex = 0

  const onLeft = () => {
    btnIndex = Math.max(btnIndex - 1, 0)
  }

  const onRight = () => {
    btnIndex = Math.min(btnIndex + 1, buttons.length - 1)
  }

  const onPlay = () => {
    playback.play($detail.video)
    focus.focusOn('player')
  }

  const onBack = () => {
    dispatch('back')
  }

  const onEnter = () => {
    switch (buttonInFocus) {
      case 'play':
        onPlay()
        break
      case 'back':
        onBack()
        break
    }
  }

  let runtime: number
  let runtimeStr: string

  $: buttonInFocus = buttons[btnIndex]
  $: details = $detail?.id > 0 ? getDetails($detail.type, $detail.id) : undefined
  $: details?.then((_details) => {
    runtime = getRuntime(_details)
    runtimeStr = getRuntimeStr(runtime)
  })
  $: poster = getPosterUrl(
    $detail.poster,
    $screen.viewport.width * (1 / 3) * (5 / 6) * window.devicePixelRatio
  )
  $: backdrop = getBackdropUrl($detail.backdrop, $screen.viewport.width * window.devicePixelRatio)
  $: focus.register(id, { onLeft, onRight, onEnter, onBack })
</script>

{#if $detail}
  {#await details}
    <p>Loading...</p>
  {:then _details}
    <section>
      <div class="detail-preview">
        <img src={backdrop} alt="Backdrop for {$detail.name}" />
      </div>

      <div class="detail-action-bar">
        <div class="w-1/3" />
        <div class="w-2/3 detail-action-bar-buttons">
          <button aria-current={buttonInFocus === 'play' ? true : undefined}>Trailer</button>
          <button aria-current={buttonInFocus === 'back' ? true : undefined}>Back</button>
        </div>
      </div>

      <div class="detail-detail">
        <div class="w-1/3">
          <div class="w-5/6 detail-poster">
            <img src={poster} alt="Poster for {$detail.name}" />
          </div>
        </div>

        <div class="w-2/3">
          <h1 class="detail-name">{$detail.name}</h1>
          {#if _details.tagline}
            <h2 class="detail-tagline">{_details.tagline}</h2>
          {/if}
          <p class="detail-details">
            <span class="detail-length">{runtimeStr}</span>
            <span class="detail-outline">5.1</span>
            <span class="detail-outline">HD</span>
          </p>
          <p class="detail-description">{$detail.description}</p>
        </div>
      </div>
    </section>
  {:catch err}
    <p>Error fetching details: {err}</p>
  {/await}
{:else}
  <p>Detail not found</p>
{/if}
