<script lang="ts">
  import type { FocusTarget } from '@velope/ott-o-rama-core/navigation'
  import { focus } from '../stores'
  import { iFrameHandler } from '@velope/ott-o-rama-core/iFrameHandler'

  export let id: string
  export let title: string
  export let items: string[]
  export let onRight: FocusTarget = null

  let index = 0

  const frameHandler = new iFrameHandler()
  frameHandler.init()
  
  const moveUp = () => {
    if (index > 0) index -= 1
  }

  const moveDown = () => {
    if (index < items.length - 1) index += 1
  }

  const onEnter = () => {
    focus.focusOn('carousel:1')
  }

  const onBack = () => {
    frameHandler.sendFocusToParent()
  }

  $: focus.register(id, { onUp: moveUp, onDown: moveDown, onEnter, onRight, onBack })
  $: focused = id === $focus.focused
</script>

<div class="drawer-drawer">
  <div class="drawer-title">{title}</div>
  <div class="drawer-buttons">
    {#each items as item, i}
      <div class="drawer-button" aria-current={focused && i === index ? true : undefined}>
        {item}
      </div>
    {/each}
  </div>
</div>
