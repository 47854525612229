<script context="module" lang="ts">
  import { doScroll, doTransform } from '@velope/ott-o-rama-core/motion'
  import { isEnabled } from '@velope/ott-o-rama-core/cloudinary'
  import { useWebWorker } from '@velope/ott-o-rama-core/webworker'

  const _doScroll = doScroll()
  const _doTransform = doTransform()
  const useCloudinary = isEnabled()
  const _useWebWorker = useWebWorker()
</script>

<script lang="ts">
  import type { Detail } from '@velope/ott-o-rama-core/media'
  import type { FocusTarget } from '@velope/ott-o-rama-core/navigation'
  import { createEventDispatcher } from 'svelte'
  import { shouldLoadMore, getX, adjustLeft } from '@velope/ott-o-rama-core/carousel'
  import { cloudinaryUrl, ITEM_WIDTH } from '@velope/ott-o-rama-core/item'
  import { getPosterUrl } from '@velope/ott-o-rama-core/media'
  import { focus, detail, screen, webWorker } from '../stores'
  import Item from './Item.svelte'
  import Scroll from './Scroll.svelte'
  import Transform from './Transform.svelte'

  export let id: string
  export let items: Detail[]
  export let onUp: FocusTarget = null
  export let onDown: FocusTarget = null
  export let onLeft: FocusTarget = null
  export let onRight: FocusTarget = null

  const dispatch = createEventDispatcher()

  let index = 0
  let prevItems: Detail[] = []

  $: if (items && shouldLoadMore(items.length, index)) dispatch('page')

  $: value = getX(index) * $screen.scale
  $: scrollValue = _doScroll ? value : 0
  $: transformValue = _doTransform ? -value : 0

  $: width = ITEM_WIDTH * $screen.scale * window.devicePixelRatio

  const moveLeft = () => {
    if (index > 0) index -= 1
  }

  const moveRight = () => {
    if (index < items.length - 1) index += 1
  }

  const onEnter = () => {
    if (items[index].id !== $detail.id) $detail = items[index]
    focus.focusOn('detail')
  }

  const onBack = () => {
    dispatch('back')
  }

  const addToWebWorker = (data: Detail[]) => {
    let posters = {}
    let postersOnFocus = {}
    data.map((item) => {
        if (useCloudinary) {
            posters = {
                ...posters,
                [`false-cloudinary${item.poster}`]: cloudinaryUrl(item, false, width),
            }
            postersOnFocus = {
                ...postersOnFocus,
                [`true-cloudinary${item.poster}`]: cloudinaryUrl(item, true, width),
            }
        } else {
            posters = { ...posters, [item.poster]: getPosterUrl(item.poster, width) }
        }
    })
    webWorker.add(posters)
    if (useCloudinary) webWorker.add(postersOnFocus)
  }

  if (_useWebWorker) addToWebWorker(items)

  $:{
    if (_useWebWorker && items.length > prevItems.length) {
      const newItemsDiff = items.filter((item) => !prevItems.includes(item))
      addToWebWorker(newItemsDiff)
    }
    focus.register(id, {
      onUp,
      onDown,
      onLeft: index > 0 ? moveLeft : onLeft,
      onRight: index < items.length - 1 ? moveRight : onRight,
      onEnter,
      onBack,
    })
    prevItems = items
  }

  $: focused = id === $focus.focused
</script>

<Scroll property="scrollLeft" value={scrollValue}>
  <div class="carousel">
    <Transform x={transformValue} y={0}>
      {#each items ?? [] as item, i}
        <div class="carousel-item-wrapper" style:left={adjustLeft(i)}>
          <Item {item} focused={focused && i === index} />
        </div>
      {/each}
    </Transform>
  </div>
</Scroll>
